import setting from '../settings';

const quotationUrlApi = setting.quotationUrlApi;
const exceptionCodeResponse = setting.exeptionCodeResponse;
const validateFileResponse = setting.validateFileResponse;

export const getImgTypeBlob = async (data) => {
	const headers = {
		'Content-Type': 'application/json',
	};

	const body = JSON.stringify(data);
	const options = { method: 'POST', headers, body }

	return await fetch(`${quotationUrlApi}file/getFile`, options)
		.then(response => validateFileResponse(response))
		.then(blob => URL.createObjectURL(blob))
		.catch((error) => {
			throw exceptionCodeResponse(error);
		});
};