import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import { Grid, Hidden } from '@material-ui/core';
import Sticky from 'react-sticky-el';
import ModalContact from '../modal/modalContact';
import Logo from '../../assets/images/iconnectance_isotipo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getUserConfig } from '../../actions/configActions';
import settings from '../../settings';
import { getImg } from '../../utils/fileManagement';

const repositoryCode = settings.repositoryCode;

const LayoutHeader = ({ customHeader }) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config.data);
    const [logoImage, setLogoImage] = useState('');

    useEffect(() => {
        dispatch(getUserConfig());
    }, []);

    const initImg = async (path, repositoryCode) => {
        const { urlBlob } = await getImg(path, repositoryCode);
        setLogoImage(urlBlob)
    }

    useEffect(() => {
        if (repositoryCode !== '' && logoImage === '' && config.logoImage) {
            initImg(config.logoImage, repositoryCode);
        }
    }, [logoImage, repositoryCode, config])

    return (
        customHeader ? <div
            style={{ width: "100%", marginBottom: 32 }}
            dangerouslySetInnerHTML={{ __html: customHeader }}
        /> : <div style={{ minHeight: 148, backgroundColor: '#ffffff', padding: '12px 0px' }}>
            <div style={{ zIndex: 100 }} className="header-sticker">
                <Grid container className="header" alignItems="center" justify="space-between">
                    <Grid item xs={9} sm={10}>
                        <Link to="" onClick={() => window.location.href = '/'}>
                            <Hidden xsDown>
                                <img alt="Logo_IConnectance1" src={logoImage} style={{ height: '40px' }} />
                            </Hidden>
                            <Hidden smUp>
                                <img alt="Logo_IConnectance2" src={logoImage} style={{ height: '40px' }} />
                            </Hidden>
                        </Link>
                    </Grid>
                    <Grid item xs={3} sm={2}><ModalContact /></Grid>
                </Grid>
            </div>
        </div>
    );
};

export default LayoutHeader;
