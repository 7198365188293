import { getImgTypeBlob } from "./getFile";


/** 
 * @param {String} path 
 * @return {Object} - {name, urlBlob, path}
 */
export const getImg = async (path, repositoryCode) => {
    try {

        if (path === null) return;

        const urlBlob = await getImgTypeBlob({ fileRoute: path, repositoryCode });
        return { urlBlob, path };
    } catch (error) {
        console.error('Error get img:', error);
    }
}